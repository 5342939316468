@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1; /* Use a valid opacity value */
  }
}

.single_slider {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 90vh;
  background-image: url('../../Component/photos\ _33.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.single_slider .slider_content {
  position: relative;
  z-index: 2;
  animation: slideInFromRight 2s ease-out;
  padding: 20px;
  color: white;
  text-align: center;
}

.single_slider .btn-primary {
  background-color: #1bb55c;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  min-width: 150px;
  white-space: nowrap;
}

@media (max-width: 1200px) {
  .single_slider .slider_content {
    font-size: 0.9rem;
  }
}

@media (max-width: 992px) {
  .single_slider {
    height: 100vh; /* Adjusted to keep consistent height */
  }

  .single_slider .slider_content {
    font-size: 0.8rem;
  }

  .single_slider .btn-primary {
    padding: 0.5rem 1rem;
  }
}

@media (max-width: 768px) {
  .single_slider {
    height: 80vh; /* Slightly smaller height on smaller screens */
    background-size: cover;
  }

  .single_slider .slider_content {
    font-size: 0.7rem;
  }

  .single_slider .btn-primary {
    padding: 0.5rem 0.75rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .single_slider {
    height: 75vh; /* Further reducing height */
  }

  .single_slider .slider_content {
    font-size: 0.6rem;
  }

  .single_slider .btn-primary {
    font-size: 0.8rem;
    padding: 0.4rem 0.6rem;
  }
}
