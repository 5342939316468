/* 
.container {
    max-width: 80%;
  
  } */
  
  h2 {
    color: #2e8b57; 
  }
  
  .carousel-control-next, .carousel-control-prev {
    display: none;
  }
  .TeamMemberCard {
    background-color: #f0f8f5; 
    border-radius: 10px;
    padding: 15px;
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
  }
  
  .TeamMemberCard:hover {
    transform: translateY(-10px); 
  }
  
  .TeamMemberCard img {
    border: 2px solid #2e8b57; 
  }
  
  .TeamMemberCard h5 {
    color: #2e8b57; 
    font-weight: bold;
  }
  
  .TeamMemberCard p {
    color: #555;
  }
 
  
 
  .TeamMemberCard .d-flex a {
    color: #2e8b57; 
    font-size: 1rem;
    transition: color 0.3s;
  }
  
  .TeamMemberCard .d-flex a:hover {
    color: #1d5e3e; 
  }
  
  .d-flex {
    gap: 15px;
  }
  