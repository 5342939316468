.sidebar {
  width: 250px;
  height: 90vh;
  background-color: #f8f9fa;
  border-right: 1px solid #dee2e6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 100px;
  z-index: 1;
}

.content-left {
  flex-grow: 1;
  padding: 20px;
}

.nav-link {
  color: #000;
  padding: 10px;
  display: flex;
  align-items: center;
}

.nav-link.active {
  background-color: #007bff;
  color: #fff;
}

hr {
  margin: 10px 0;
}

/* Add media query to hide sidebar for smaller screens */
@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}
