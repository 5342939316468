.footer {
  background-color: #eceff1;
  /* padding: 50px 0; */
  color: #333;
  z-index: 10;
}
.me-5 {
  z-index: 99;
}

.footer_top {
  padding: 50px 0;
}

.footer .footer_title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.footer .address_text {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 30px;
}

.footer .social_links ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.footer .social_links ul li {
  margin-right: 15px;
}

.footer .social_links ul li a {
  display: inline-block;
}

.footer .social_links ul li img {
  width: 40px;
  height: 40px;
}

.footer_widget {
  margin-bottom: 30px;
}

.footer_widget .links {
  padding: 0;
  margin: 0;
  list-style: none;
}

.footer_widget .links li {
  margin-bottom: 10px;
}

.footer_widget .links li a {
  color: #555;
  text-decoration: none;
}

.footer_widget .links li a:hover {
  color: #28a745;
}

.footer_widget .contacts {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

.footer_widget .news_links {
  padding: 0;
  margin: 0;
  list-style: none;
}

.footer_widget .news_links li {
  display: flex;
  margin-bottom: 15px;
}

.footer_widget .news_links .thumb {
  margin-right: 15px;
}

.footer_widget .news_links .thumb img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.footer_widget .news_links .info h4 {
  font-size: 16px;
  color: #333;
  margin: 0;
  line-height: 1.4;
}

.footer_widget .news_links .info h4:hover {
  color: #21d192;
}

.copy-right_text {
  padding: 20px 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.copy_right {
  margin: 0;
  color: #555;
}

.copy_right a {
  color: #333;
  text-decoration: none;
}

.copy_right a:hover {
  color: #21d192;
}
.social_links a {
  display: inline-block;
  color: white;
  transition: color 0.3s ease;
}

.social_links a:hover {
  color: #f1f1f1; /* Lighter color on hover */
}

.social_links svg {
  margin-right: 10px; /* Space between icons */
}

@media (max-width: 767px) {
  .footer_widget {
    margin-bottom: 20px;
  }

  .footer .social_links ul li img {
    width: 30px;
    height: 30px;
  }

  .footer_widget .news_links .thumb img {
    width: 60px;
    height: 60px;
  }
}
