.privacy-content {
  font-size: 1rem;
  line-height: 1.6;
  padding: 50px;
}
.privacy-banner {
  width: 100%;
  height: 70vh;
}
.privacy111 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
