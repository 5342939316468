.banner-container {
  height: 300px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

.banner-heading {
  font-size: 2.5rem;
  font-weight: bold;
}

.banner-description {
  margin-top: 10px;
  font-size: 1.2rem;
}

.section-title {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  color: #333;
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Ensures three cards per row */
  gap: 20px;
  margin-bottom: 3rem;
}

.service-card {
  background: #f8f9fa;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.service-icon {
  font-size: 2.5rem;
  color: #21D192; /* Updated color */
  margin-bottom: 15px;
}

.achievement-list {
  list-style-type: none;
  padding: 0;
  font-size: 1.1rem;
  background: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.achievement-list li {
  margin-bottom: 10px;
  line-height: 1.5;
}

.flip-card-container {
  display: flex;
  justify-content: space-between; /* Align cards in a single row */
  gap: 20px;
  flex-wrap: nowrap; /* Prevent wrapping to multiple rows */
  overflow-x: auto; /* Add horizontal scroll if cards exceed container width */
  padding-bottom: 15px;
}

.flip-card {
  background-color: transparent;
  width: 250px; /* Reduced width for better spacing */
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 350px; /* Adjusted height for a uniform look */
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
}

.flip-card-front {
  background: #21D192;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.flip-card-front img {
  border-radius: 50%;
  width: 80px; /* Adjusted size for better alignment */
  height: 80px;
  margin-bottom: 10px;
}

.flip-card-back {
  background: #f8f9fa;
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.achievement-card {
  background: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.achievement-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}



.achievement-icon {
  font-size: 2rem;
  color: #21D192; /* Updated color */
  margin-bottom: 15px;
}

