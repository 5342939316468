.donation_form {
  padding: 30px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 600px;
  margin: 0 auto;
}

.donation_form h3 {
  font-size: 24px;
  font-weight: 700;
  color: #1bb55c;
}

.donation_form h4 {
  font-size: 18px;
  color: #1bb55c;
  margin-bottom: 15px;
}

.form-check {
  margin-bottom: 10px;
}

.form-check-input {
  margin-right: 10px;
}

.form-check-label {
  font-size: 16px;
  color: #333;
}

/* Button styling */
.btn-success {
  background-color: #1bb55c;
  border-color: #1bb55c;
  font-size: 20px;
  padding: 15px 30px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.btn-success:hover {
  background-color: #169e4c;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup_inner {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  width: 50%px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.popup_inner .btn {
  margin: 10px;
  font-size: 18px;
  padding: 12px 24px;
  border-radius: 5px;
}

.popup_inner .btn-primary {
  background-color: #1bb55c;
  border-color: #1bb55c;
}

.popup_inner .btn-primary:hover {
  background-color: #169e4c;
}

.popup_inner .btn-secondary {
  background-color: #f0f0f0;
  border-color: #dcdcdc;
}

.popup_inner .btn-secondary:hover {
  background-color: #e0e0e0;
}
@media (max-width: 767px) {
  .donation_form {
    width: 100%;
  }
  .col-12:nth-child(6) div {
    justify-content: unset !important;
    width: 90%;
    margin: 0 auto;
  }
}
