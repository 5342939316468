.background-container {
  position: relative;
  width: 100%;
  height: 70vh;
  background: url('../../Component/Contactus.jpg') no-repeat center center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  @media (max-width: 768px) {
    height: 50vh;
    width: 100%;
  }
  @media (max-width: 480px) {
    height: 40vh;
    width: 100%;
  }
}

.content-container {
  position: relative;
  /* padding-top: 320px;  */
  background-color: #fff;
}

/* Contact content styles */
.contact-content {
  padding: 40px;
}

/* Contact form styles */
.contact-form {
  padding: 20px;
  border-radius: 5px;
  background: #f8f9fa; /* Light background for the form */
  margin-top: 20px;
}

.contact-form .form-group {
  margin-bottom: 15px;
}

.contact-form .form-control {
  border-radius: 5px;
}

.contact-form .btn {
  border-radius: 5px;
}

.form-status {
  margin-top: 15px;
  font-weight: bold;
}

/* Blurb styles */
.blurb {
  text-align: center;
}

.blurb-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.contact-row {
  margin-top: 30px;
}
.Sizing {
  font-size: 3rem;
}
.Font {
  font-size: 4rem;
}
.btn-primary {
  --bs-btn-bg: #23d970;
}
.contact-page {
  padding: 20px;
}

.background-container {
  /* Add your background styling here if needed */
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-content {
  width: 100%;
}

.container {
  max-width: 1200px;
}

.contact-form {
  margin-top: 20px;
}

.form-status {
  margin-top: 10px;
  color: green;
}

.blurb {
  text-align: center;
  margin-bottom: 20px;
}

.blurb-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.contact-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact-row .blurb {
  flex: 1 1 30%;
  margin: 10px;
}

a {
  color: black; /* Make sure all links are black */
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
