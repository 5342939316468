.reson_area {
  padding: 20px 0;
  position: relative;
}

/* .background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../Component/Transparent.jpg');
  background-size: cover;
  background-position: center;
  z-index: -1;
  transition: transform 0.5s ease-in-out;
} */

.thum {
  position: relative;
  width: 100%;
  height: 50vh;
  padding-top: 100%;
  overflow: hidden;
}

.reson_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.single_reson {
  position: relative;
  margin-bottom: 30px;
  border: 1px solid #ddd;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.single_reson:hover {
  transform: scale(1.05);
}

.help_content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
  box-sizing: border-box;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease, visibility 0.4s ease;
}

.single_reson:hover .help_content {
  opacity: 1;
  visibility: visible;
}

.read_more {
  color: #1bb55c;
  text-decoration: none;
  display: inline-block;
  margin-top: 10px;
}

.read_more:hover {
  text-decoration: underline;
}

.reson_area:hover .background-image {
  transform: scale(1.1);
}
.reson_area {
  padding: 50px;
  position: relative;
}
