/* Add this CSS to style the counter section */
.ftco-counter {
  padding: 3rem 0;
}

.ftco-intro {
  background: #f7f7f7;
}

.block-18 {
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 1rem; /* Add space around each card */
}

.color-1 {
  background-color: #f9f9f9;
}

.color-2 {
  background-color: #eaf6f6;
}

.color-3 {
  background-color: #f6f9ea;
}

.text {
  text-align: center;
}

.title {
  display: block;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.number {
  font-size: 3rem;
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.content {
  display: block;
}

.btn-white {
  color: #333;
  background: #fff;
  border: 1px solid #ddd;
}

.btn-white:hover {
  color: #000;
  background: #f9f9f9;
  border-color: #ccc;
}
