.our_volunteer_area {
  padding: 50px 0;
}

.single_volunteer {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.single_volunteer:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}

.volunteer_thumb img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.social_links .social-icon {
  width: 24px;
  height: 24px;
}

.info_inner {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.volunteer_description {
  text-align: center;
  flex: 1;
}

.read_more_btn {
  /* background-color: #007bff; */
  /* color: #151010; */
  /* border: none; */
  /* padding: 5px 10px; */
  /* border-radius: 5px; */
  cursor: pointer;
  margin-top: 10px;
}

.read_more_btn:hover {
  background-color: #0056b3;
}
.Images {
  margin-left: 30px;
  margin-right: 30px;
}
.info_inner h4 {
  min-height: 58px;
}
