.page {
  padding: 20px;
}

.image-container {
  text-align: center;
}

.img-fluid {
  width: 100%;
  max-width: 100%;
  height: 100vh;
}

.content {
  margin-left: 50px;
}

.content h2,
.content h3,
.content h4 {
  font-size: x-large;
  margin-top: 20px;
  margin-bottom: 10px;
}

.content p {
  margin-bottom: 15px;
}

.content ul {
  margin-left: 50px;
  margin-bottom: 15px;
}
.page {
  padding: 20px;
}

.image_container {
  text-align: center;
  margin-bottom: 20px;
}

.content_container {
  max-width: 800px;
  margin: 0 auto;
}

h2,
h3,
h4 {
  color: #333;
}

.mission_section,
.offer_section,
.why_choose_us,
.get_involved,
.early_goal_settings {
  margin-bottom: 30px;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}
