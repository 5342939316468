.popular_causes_area {
  padding: 60px 24px;
}

.cause_image {
  width: 100%;
  height: 300px;

  object-fit: cover;
}

.single_cause {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 100%;
}

.section_title {
  margin-bottom: 55px;
}

.read_more {
  display: inline-block;
  margin-top: 10px;
  color: #1bb55c;
  text-decoration: none;
}

.section_title h3 {
  color: #1bb55c;
}

.single_cause {
  border: 2px solid #1bb55c;
  border-radius: 8px;
  overflow: hidden;
}

.thumb img {
  width: 100%;
  height: 300px; /* Ensure that all images have the same height */
  display: block;
  object-fit: cover; /* Ensure images cover the space without distortion */
}

.causes_content {
  padding: 15px;
  border-top: 2px solid #1bb55c;
  min-height: 246px;
}

.progress-bar {
  background-color: #1bb55c;
}

.read_more {
  color: #1bb55c;
  text-decoration: none;
}

.read_more:hover {
  text-decoration: underline;
}

.popular_causes_area {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popular_causes_area .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.popular_causes_area .col-lg-4 {
  display: flex;
  justify-content: center;
  padding: 15px;
  /* padding-left: 35px; */
}

.popular_causes_area .single_cause {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 100%;
}
.causes_content h4 {
  min-height: 58px;
}
