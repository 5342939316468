@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.header-bottom {
  background-color: transparent;
  box-shadow: none;
}

.navbar-brand {
  font-size: 24px;
  font-weight: bold;
  color: #0b0b45;
  display: flex;
  align-items: center;
}

.navbar-brand span {
  color: #23d970;
  font-size: 30px;
  margin-left: 2px;
}

.navbar-nav {
  /* margin-left: auto; */
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.navbar-nav .nav-link {
  color: #0b0b45;
  font-size: 16px;
  /* margin-right: 3.5rem; */
}

.navbar-nav .nav-link:hover {
  color: #0b0b45;
}

.header-btn {
  background-color: #23d970;
  color: white;
  padding: 0.75rem 2rem;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  /* margin-left: 1rem; */
}

.header-btn:hover {
  background-color: #1bb55c;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler-icon:before {
  content: '\2630';
  color: black;
  font-size: 1.5rem;
}

.navbar-nav .nav-link {
  color: #333;
  padding: 10px 15px;
  transition: color 0.3s ease;
}

/* Hover effect for the text */
.navbar-nav .nav-link:hover {
  color: #28a745; /* Change text color on hover */
}

/* Base style for the button */
.header-btn {
  color: #fff;
  background-color: #28a745;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  transition: color 0.3s ease, background-color 0.3s ease;
}




.navbar-nav .nav-link, .navbar-brand, .header-btn {
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  font-size: 16px; 
  color: #333; 
}

.navbar-brand {
  font-size: 20px; 
  font-weight: 200;
}

.header-btn {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  background-color: #28a745; /* Or any custom color */
  border: none;
  border-radius: 5px;
  margin-left: 10px;
  padding: 8px 12px;
  transition: background-color 0.3s ease;
}

.header-btn:hover {
  background-color: #28a745; /* Darker shade for hover */
}

/* Hover effect for the button text */
.header-btn:hover {
  background-color: #28a745; /* Keep the background color the same */
  color: #ffeb3b; /* Change text color on hover */
}
@media (max-width: 992px) {
  .navbar-collapse {
    background-color: white;
    padding: 1rem;
  }

  .nav-link {
    padding: 0.5rem 1rem;
  }

  .header-btn {
    width: 100%;
    text-align: center;
  }
}
