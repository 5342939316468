.img-fluid {
  max-width: 100%;
  height: 100%;
}

.activity-text {
  font-size: 1rem;
  line-height: 1.5;
}

.header-btn {
  /* margin: 0.5rem; */
  flex: 1;
  max-width: 200px;
  text-align: center;
}

@media (max-width: 768px) {
  .img-fluid {
    width: 100%;
    max-width: 100%;
  }

  .Latest {
    font-size: 1.5rem;
  }

  .activity-text {
    font-size: 0.9rem;
  }

  .header-btn {
    width: 100%; /* Full width on small screens */
    margin-bottom: 1rem; /* Space between buttons */
  }
}
